export const commonQuickDownloadData = [
  {
    id: "cm_ac_1",
    title: "Security Files",
    description:
      "Download selected date's files from ntneat folder. Includes selected date's security.gz and contract.gz files",
    downloadLinks: [
      {
        folderPath: "ntneat",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CM",
  },
  {
    id: "cm_ac_2",
    title: "Bhavcopy",
    description:
      "Download selected date's bhavcopy files. Includes .md and .ms files",
    downloadLinks: [
      {
        folderPath: "bhavcopy",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CM",
  },
  {
    id: "cm_ac_3",
    title: "Usual BOD Files",
    description:
      "Download selected day's files from ntneat, bhavcopy, tbt_masters folders. Includes contract.gz, security.gz, cm_contract_stream_info.csv",
    downloadLinks: [
      {
        folderPath: "bhavcopy",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
      {
        folderPath: "ntneat",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
      {
        folderPath: "tbt_masters",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CM",
  },
  {
    id: "cm_ac_4",
    title: "Clearing Files",
    description:
      "Download selected date's files from clearing folder. Includes C_STT, C_SEC, C_VAR files etc",
    downloadLinks: [
      {
        folderPath: "clearing",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CM",
  },
  {
    id: "cm_ac_5",
    title: "Auction Files",
    description:
      "Download selected date's files from auction folder. Includes AUBA, CSQR files etc",
    downloadLinks: [
      {
        folderPath: "auction",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CM",
  },
  {
    id: "cm_ac_6",
    title: "WAP",
    description:
      "Download selected date's files from wap folder. Includes A,D files etc",
    downloadLinks: [
      {
        folderPath: "wap",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CM",
  },
  {
    id: "cm_ac_7",
    title: "Varrate",
    description:
      "Download files created today from varrate folder. Include C_VAR files",
    downloadLinks: [
      {
        folderPath: "varrate",
        type: "folder",
        segment: "CM",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CM",
  },
  {
    id: "fo_ac_6",
    title: "Contract files",
    description:
      "Download selected day's Security and Contract realted files. Includes fo_contract_stream_info.csv, fo_spd_contract_stream_info.csv,",
    downloadLinks: [
      {
        folderPath: "",
        type: "file",
        filename: "spd_contract.gz",
        segment: "FO",
        userType: "common",
      },
      {
        folderPath: "",
        type: "file",
        filename: "fo_participant.gz",
        segment: "FO",
        userType: "common",
      },
      {
        folderPath: "tbt_masters",
        type: "folder",
        segment: "FO",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "FO",
  },
  {
    id: "fo_ac_1",
    title: "Bhavcopy",
    description:
      "Download selected date's bhavcopy files. Includes fo**bhav.csv.gz, .md and .ms files",
    downloadLinks: [
      {
        folderPath: "Bhavcopy",
        type: "folder",
        segment: "FO",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "FO",
  },
  {
    id: "fo_ac_2",
    title: "Limit Files",
    description:
      "Download selected date's Limit Files. Includes ael, secban files",
    downloadLinks: [
      {
        folderPath: "Limit File",
        type: "folder",
        segment: "FO",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "FO",
  },
  {
    id: "fo_ac_3",
    title: "MarketReports",
    description:
      "Download selected date's files from MarketReport folder. Includes FO_CN, F_CTM files",
    downloadLinks: [
      {
        folderPath: "MarketReports",
        type: "folder",
        segment: "FO",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "FO",
  },
  {
    id: "fo_ac_4",
    title: "Parameter",
    description:
      "Download files created today from Parameter folder. Includes spn files",
    downloadLinks: [
      {
        folderPath: "Parameter",
        type: "folder",
        segment: "FO",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "FO",
  },
  {
    id: "fo_ac_5",
    title: "Volatility",
    description:
      "Download files created today from Volatility folder. Includes FOVOLT files",
    downloadLinks: [
      {
        folderPath: "Volatility",
        type: "folder",
        segment: "FO",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "FO",
  },
  {
    id: "cd_ac_1",
    title: "Parameter",
    description:
      "Download files created today from Parameter folder. Includes nsccl_*.spn files",
    downloadLinks: [
      {
        folderPath: "Parameter",
        type: "folder",
        segment: "CD",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CD",
  },
  {
    id: "cd_ac_2",
    title: "MarketReports",
    description:
      "Download files creted today from MarketReport folder. Includes X_CN, Applicable_Contract_CD files",
    downloadLinks: [
      {
        folderPath: "MarketReports",
        type: "folder",
        segment: "CD",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CD",
  },
  {
    id: "cd_ac_3",
    title: "Bhavcopy",
    description:
      "Download files creted today from Bhavcopy folder. Includes .yms, .md, .ms",
    downloadLinks: [
      {
        folderPath: "Bhavcopy",
        type: "folder",
        segment: "CD",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CD",
  },
  {
    id: "cd_ac_4",
    title: "Contract Files",
    description:
      "Download files creted today from tbt_masters folder. Includes cd_spd_contract_stream_info.csv, cd_contract_stream_info.csv, ",
    downloadLinks: [
      {
        folderPath: "tbt_masters",
        type: "folder",
        segment: "CD",
        userType: "common",
      },
      {
        folderPath: "",
        type: "file",
        filename: "cd_contract.gz",
        segment: "CD",
        userType: "common",
      },
      {
        folderPath: "",
        type: "file",
        filename: "cd_participant.gz",
        segment: "CD",
        userType: "common",
      },
      {
        folderPath: "",
        type: "file",
        filename: "cd_spd_contract.gz",
        segment: "CD",
        userType: "common",
      },
    ],
    userType: "common",
    segment: "CD",
  },
];

export const memberQuickDownloadData = [
  {
    title: "Trade Reports",
    description:
      "Download selected date's files from Reports, Order_Reports, PNL01, PNL02, PNL03, Onlinebackup folders",
    downloadLinks: [
      {
        folderPath: "/Reports",
        type: "folder",
        segment: "CM",
        userType: "member",
      },
      {
        folderPath: "/Reports/Order_Reports",
        type: "folder",
        segment: "CM",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL01",
        type: "folder",
        segment: "CM",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL02",
        type: "folder",
        segment: "CM",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL03",
        type: "folder",
        segment: "CM",
        userType: "member",
      },
      {
        folderPath: "/Onlinebackup",
        type: "folder",
        segment: "CM",
        userType: "member",
      },
    ],
    userType: "member",
    segment: "CM",
  },
  {
    id: "fo_ac_6",
    title: "Trade Reports",
    description:
      "Download selected date's FO Trade files from Reports, Order_Reports, PNL01, PNL02, PNL03, Onlinebackup folders",
    downloadLinks: [
      {
        folderPath: "/Reports",
        type: "folder",
        segment: "FO",
        userType: "member",
      },
      {
        folderPath: "/Reports/Order_Reports",
        type: "folder",
        segment: "FO",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL01",
        type: "folder",
        segment: "FO",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL02",
        type: "folder",
        segment: "FO",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL03",
        type: "folder",
        segment: "FO",
        userType: "member",
      },
      {
        folderPath: "/Onlinebackup",
        type: "folder",
        segment: "FO",
        userType: "member",
      },
    ],
    userType: "member",
    segment: "FO",
  },
  {
    id: "cd_ac_6",
    title: "Trade Reports",
    description:
      "Download selected date's CD Trade files from Reports, Order_Reports, PNL01, PNL02, PNL03, Onlinebackup folders",
    downloadLinks: [
      {
        folderPath: "/Reports",
        type: "folder",
        segment: "CD",
        userType: "member",
      },
      {
        folderPath: "/Reports/Order_Reports",
        type: "folder",
        segment: "CD",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL01",
        type: "folder",
        segment: "CD",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL02",
        type: "folder",
        segment: "CD",
        userType: "member",
      },
      {
        folderPath: "/Reports/Dnld/PNL03",
        type: "folder",
        segment: "CD",
        userType: "member",
      },
      {
        folderPath: "/Onlinebackup",
        type: "folder",
        segment: "CD",
        userType: "member",
      },
    ],
    userType: "member",
    segment: "CD",
  },
];
