import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";

import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// icons
import FolderIcon from "@mui/icons-material/Folder";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Avatar, Badge, MenuItem } from "@mui/material";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import StyledMenu from "./StyledMenu";
import { Box, Stack } from "@mui/system";
import { logout } from "../utils";
import Timer from "./Timer";

const drawerWidth = 340;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const SideBar = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <AppBar position="sticky" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            sx={{
              mr: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ color: "white" }}>
              FundsMap X-Connect
            </Link>
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            {user.planType === "TRIAL_EXPIRED" && user?.remainingTime && (
              <Timer expiryTimestamp={user?.remainingTime} />
            )}

            <Typography fontWeight="bold">
              Member ID: {user?.memberCd}
            </Typography>
            {user?.profileUpdateRequired ? (
              <StyledBadge
                overlap="circular"
                color="error"
                variant="dot"
                onClick={handleClick}
                hidden={true}
              >
                <Avatar alt="Avatar" sx={{ cursor: "pointer" }} />
              </StyledBadge>
            ) : (
              <Avatar
                alt="Avatar"
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
              />
            )}
          </Stack>
          <StyledMenu
            anchorEl={anchorEl}
            open={anchorEl ? true : false}
            onClose={handleClose}
          >
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  padding: "0 15px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <b>Member ID</b>
                <p>{user?.memberCd}</p>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  padding: "0 15px",
                  gap: "20px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <b>Login ID</b>
                <p>{user?.loginId}</p>
              </Box>
            </Stack>
            <Divider />
            <MenuItem
              disableRipple
              selected={user?.profileUpdateRequired}
              onClick={() => navigate("/profile")}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgba(237, 53, 40,.7)",
                  color: "white",
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "#f51c0c",
                },
              }}
            >
              <AccountCircleIcon
                sx={
                  user?.profileUpdateRequired && {
                    color: "white",
                    fill: "white",
                  }
                }
              />
              Profile
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                logout({
                  loginId: user.loginId,
                  memberCode: user?.memberCd,
                  token: user?.token,
                })
              }
            >
              <LogoutIcon />
              Logout
            </MenuItem>
          </StyledMenu>
        </Toolbar>
        {/* <Toolbar>
          <Box display="flex" alignItems="center">
          </Box>
        </Toolbar> */}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .REPLACE-MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={closeDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={closeDrawer}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <NavLink
              to="/"
              className={({ isActive }) => `${isActive ? "nav-active" : ""}`}
              style={{
                width: "100%",
              }}
            >
              <ListItemButton onClick={closeDrawer}>
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary="NSE Extranet" />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default SideBar;
