import { useState } from "react";

// material
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Link,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { api } from "../api";

import { useTopLoader } from "../context/TopLoaderContext";
import { loginUser } from "../redux/slices/userSlice";

import { encryptString, statusCodeParser } from "../utils";

// icons
import BuildIcon from "@mui/icons-material/Build";
import { CircularProgress } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const theme = createTheme();

function SimpleAccordion() {
  return (
    <div>
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>When is NSE FTP access going to be discontinued?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          NSE is planning to stop the internet based FTP access on 18th Feb 2023. This date comes after an extenstion by NSE from an earlier deadline date of 28 January 2023. 
          You can read the  NSE/MSD/55362 circular <a class="hover" href="https://archives.nseindia.com/content/circulars/MSD55362.zip" > <u>here.</u></a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How does your portal work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We use the extranet API provided by NSE.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>How to generate User Credentials to use the website?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p> Please follow detailed guide <a href="https://www.fundsmap.com/x-connect-activation" > <u>here</u ></a> to create a user on NSE's portals.
          </p>
          <p>After creating the users, you will have the following information with you: <ul><li> Member Code</li> <li>Login ID</li> <li> Password</li><li> Secret Key</li></ul></p>
          <p>You can use the <b>secret key</b> and <b>password</b> to generate an encrypted password on this page.</p>
          <p>Use the <b>Member ID</b>, <b>Login ID</b> and this <b>Encrypted password</b> to login to the website using <a href = "https://xconnect.fundsmap.com/login"><u>this link</u></a></p> 
          [Note: Use this encrypted password to login and not the password you had used to create the user on NSE portal]
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Are you affliated to NSE?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No. We(FundsMap) are a 3rd Party fintech entity providing technology solution to access NSE extranet files. 
            We have NO business relationship/affliation with NSE.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Relevant Links</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            NSE Portal Links:
            <ul>
            <li><a href="https://enit.nseindia.com/MemberPortal/" > <u>ENIT Portal</u ></a> -  To register new users for extranet usage</li>            
            <li><a href="https://ims.connect2nsccl.com/MemberPortal" > <u>IMS NSE Portal</u ></a> - For FTP password setting and resetting for a user</li>
            </ul>

            FundsMap X-Connect Links:
            <ul>
            <li><a href="https://www.fundsmap.com/x-connect" > <u>Product Details</u ></a> - Get product details like features and pricing</li>
            <li><a href="https://www.fundsmap.com/x-connect-activation" > <u>New User Guide</u ></a> - Guide on how to set up new users and start using the site</li>
            <li><a href="https://xconnect.fundsmap.com/login" > <u>Login</u ></a> </li>            
            <li><a href="https://xconnect.fundsmap.com/generate-password" > <u>Generate Password</u ></a></li>
            </ul>

            Circulars:
            <ul>
              <li><a href="https://archives.nseindia.com/content/circulars/MSD55065.zip"><u>NSE/MSD/55065</u ></a> - Master Circular containing API release details, User setup instructions</li>
              <li><a href="https://archives.nseindia.com/content/circulars/MSD55362.zip" > <u>NSE/MSD/55362</u ></a> - Extenstion of FTP Discontinuation Timeline</li>
            </ul>

          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Unable to login? Common Login Errors and Resolutions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p>Ensure you have followed the steps mentioned&nbsp;<a href="https://www.fundsmap.com/x-connect-activation"><u>here</u></a>. Ensure that password used to login is generated using&nbsp;<a href="https://xconnect.fundsmap.com/generate-password"><u>this password generation link</u></a></p>
<p>Common Errors:</p>
<p><br></br></p>
<div align="left">
    <table   border='1' >
        <tbody>
            <tr>
                <td>
                    <p><b>Error&nbsp;</b></p>
                </td>
                <td>
                    <p><b>Meaning and Resolution</b></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>NSE Error: Something went wrong</p>
                </td>
                <td>
                    <p>Some problem with NSE Extranet service. We&rsquo;re probably already on it reaching out to them. Please do drop a mail from your end to NSE as well as it might expedite the resolution process</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>NSE Error: Invalid member code or login id or password</p>
                </td>
                <td>
                    <p>Most probably the password is not generated correctly, please ensure that you have logged in to IMS portal and set the password after logging in to the ENIT portal. Ensure Step 2 and Step 3 are correctly done.&nbsp;<a href="https://www.fundsmap.com/x-connect-activation">Link</a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>NSE Error: User is disabled</p>
                </td>
                <td>
                    <p>You will need to set a new the password on the <a href="https://ims.connect2nsccl.com/MemberPortal"><u>IMS portal </u></a>and regenerate the encrypted password.&nbsp;<br></br>- Log in to IMS portal with the sub user id generated on the Extranet portal</p>
                    <p>- Click on the Extranet Registration on the left side</p>
                    <p>- Set a new password and confirm the new password</p>
                    <p>- Copy the secret key and remember this new password, and go to&nbsp;<a href="https://xconnect.fundsmap.com/generate-password"><u>this link</u></a></p>
                    <p>- Use secret key, new password to Generate password, and use this new generated encrypted password to&nbsp;<a href="https://xconnect.fundsmap.com/"><u>login on this page</u></a></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>NSE Error: Authentication token expired</p>
                </td>
                <td>
                    <p>Please re-login</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div align="left">
    <table>
        <tbody>
            <tr>
                <td><br></br></td>
                <td>
                    <p><br></br></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const GeneratePassword = () => {
  // states
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { start, stop } = useTopLoader();
  const [formData, setFormData] = useState({
    memberCode: "",
    loginID: "",
    secret: "",
    password: "",
    encrypted: "",
  });

  const dispatch = useDispatch();

  //   functions
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    start();
    try {
      const { data } = await api({
        method: "post",
        url: "/extranet-api/login/2.0",
        data: {
          memberCode: formData.memberCode,
          loginId: formData.loginID,
          password: await encryptString(formData.secret, formData.password),
        },
      });

      if (data) {
        if (data?.token) {
          const decoded = decode(data?.token);
          localStorage.setItem("token", data?.token);
          localStorage.setItem("userData", JSON.stringify(decoded));
          dispatch(loginUser({ ...decoded, token: data?.token }));
          console.log(decoded);
          toast("Login successful", {
            type: "success",
          });
          setIsLoading(false);
          stop();
        }
      } else {
        toast(statusCodeParser(data?.responseCode[0]).message, {
          type: "error",
        });
        setIsLoading(false);
        stop();
      }
    } catch (error) {
      console.log(error);
      toast("Failed to Login", {
        type: "error",
      });
      stop();
      setIsLoading(false);
    }
  };
  const handleCopy = (data) => {
    setIsCopied(true);
    navigator.clipboard.writeText(data);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          width: "100%",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
        // direction="row"
        sm={false}
      >
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          //   elevation={6}
          square
        >
          <Box
            sx={{
              // my: 8,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "black",
                fontSize: "30px",
              }}
              sizes="xl"
            >
              <BuildIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Generate Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  margin="normal"
                  size="small"
                  fullWidth
                  id="memberCode"
                  label="Member Code"
                  name="memberCode"
                  autoFocus
                  value={formData.memberCode}
                  onChange={({ target }) =>
                    setFormData({
                      ...formData,
                      memberCode: target.value,
                    })
                  }
                  disabled={isLoading}
                />
                <Tooltip title="Your Member Code">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="loginID"
                  label="Login ID Address"
                  name="loginID"
                  value={formData.loginID}
                  onChange={({ target }) =>
                    setFormData({
                      ...formData,
                      loginID: target.value,
                    })
                  }
                  disabled={isLoading}
                />
                <Tooltip title="This will be the Login ID provided by NSE">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="secret"
                  label="Secret"
                  name="secret"
                  value={formData.secret}
                  onChange={({ target }) =>
                    setFormData({
                      ...formData,
                      secret: target.value,
                    })
                  }
                  disabled={isLoading}
                />

                <Tooltip title="This secret key is sent to your registered email by NSE after you create a new user. Check 'How to generate User Credentials to us this website' in the FAQs section">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FormControl fullWidth required margin="normal">
                  <InputLabel
                    size="small"
                    htmlFor="outlined-adornment-password"
                  >
                    Password
                  </InputLabel>

                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    size="small"
                    onChange={({ target }) =>
                      setFormData({
                        ...formData,
                        password: target.value,
                      })
                    }
                    disabled={isLoading}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Tooltip title="Password that you had set for this Login ID on NSE Portal.">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Box>
              {formData.encrypted.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <FormControl fullWidth margin="normal">
                    <InputLabel
                      size="small"
                      htmlFor="outlined-adornment-password"
                    >
                      Encrypted Password
                    </InputLabel>

                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      value={formData.encrypted}
                      size="small"
                      readOnly
                      disabled={isLoading}
                      endAdornment={
                        <InputAdornment position="end">
                          <Tooltip
                            title={isCopied ? "Copied" : "Click to copy"}
                            color={isCopied ? "success" : "default"}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleCopy(formData.encrypted)}
                              onMouseDown={() => handleCopy(formData.encrypted)}
                              edge="end"
                              color={isCopied ? "success" : "default"}
                              //   color="error"
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <Tooltip title="Use this password along with Member ID and Login ID to login to this website">
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </Box>
              )}
              <Button
                type="button"
                fullWidth
                variant="contained"
                size="large"
                onClick={async () => {
                  const encrypted = await encryptString(
                    formData.secret,
                    formData.password
                  );
                  setFormData({
                    ...formData,
                    encrypted,
                  });
                }}
                color="info"
                disabled={
                  formData.secret.length === 0 ||
                  formData.password.length === 0 ||
                  isLoading
                }
              >
                {isLoading ? <CircularProgress /> : "Generate Password"}
              </Button>
            </Box>
            <Box m={1} pt={1}>
            <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                >
            <Link
                    href="/login"
                    target="_blank"
                    variant="body2"
                    fontSize="18px"
                  >
                    Login
                    <OpenInNewIcon
                      fontSize="12px"
                      sx={{
                        marginLeft: "5px",
                      }}
                    />
                  </Link>
                  </Stack>
          </Box>
          </Box>
        </Grid>
        <Grid item md={6} direction="">
          <Box
            sx={{
              // my: 8,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography component="h1" variant="h5">
              FAQs
            </Typography>
            <SimpleAccordion />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default GeneratePassword;
