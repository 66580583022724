import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Alert,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Modal,
  OutlinedInput,
  Stack,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import { useTopLoader } from "../context/TopLoaderContext";
import { loginUser, selectUser } from "../redux/slices/userSlice";

// icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { login } from "../api/auth/login";
import { captureLogin } from "../api/auth/capture";
import { allSegments } from "../constants";
import { captureActivity } from "../api/capture/activity";
import { Navigate, useSearchParams } from "react-router-dom";

const theme = createTheme();

const Login = () => {
  // alert constant
  const {
    REACT_APP_SHOW_ALERT,
    REACT_APP_ALERT_VARIANT,
    REACT_APP_ALERT_MESSAGE,
  } = process.env;

  const showAlert = REACT_APP_SHOW_ALERT === "true";

  const alertVariant = (() => {
    switch (REACT_APP_ALERT_VARIANT) {
      case "error":
        return "error";
      case "info":
        return "info";
      case "warning":
        return "warning";
      case "success":
        return "success";
      default:
        return "warning";
    }
  })();

  const alertMessage = REACT_APP_ALERT_MESSAGE;

  const x =
    "Take backup of all your NSE extranet files in one click (Just like old NSE FTP functionality), Using our new product: Fundsmap X-Rewind. ";
  const expiryMessage =
    "Your evaluation period has expired. Please upgrade to paid plan for continued access. Contact support@fundsmap.com / 022-48963634";
  // const warningMessage = 'Your trial plan access to both nseftp.com and backup utility is about to expire. You will need to upgrade to a paid plan to continue accessing nseftp.com even for a single user. You wont be able to access nseftp.com from 13th April 2023 - 4:00PM. Please upgrade to a paid plan for uninterrupted access. Contact: nseftp@fundsmap.com / 022-48963634';
  const warningMessage =
    "You are on an extended trial plan. Your access will be terminated once the trial expires. Please upgrade to paid plan for continued access.";
  const sunsetMessage =
    "Your evaluation period has expired on 8th May 2023 4:00PM. Please upgrade to a paid plan for un-interruped access.";
  // const sunsetFinalMessage =
  //   "Kindly upgrade to paid plan by today 4:00 PM for continued access.";
  const userSpecificMessage =
    "Please upgrade to a paid plan for uninterrupted access. Contact support@fundsmap.com / 022-48963634 for any queries.";
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [capturedData, setCapturedData] = useState({});

  const handleClose = () => {
    setOpen(false);
    stop();
    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { start, stop } = useTopLoader();
  const [formData, setFormData] = useState({
    memberCode: "",
    loginID: "",
    password: "",
  });

  const user = useSelector(selectUser);
  const [urlParams] = useSearchParams(window.location.search);

  const dispatch = useDispatch();

  const continueLogin = () => {
    const decoded = decode(capturedData?.user?.token);
    toast("Login successful", {
      type: "success",
    });
    setIsLoading(false);
    console.log(capturedData);
    dispatch(
      loginUser({
        ...decoded,
        clientId: capturedData?.captured?.clientId,
        profileUpdateRequired: capturedData?.captured?.profileUpdateRequired,
        token: capturedData?.user?.token,
        allowedSegments: capturedData?.captured?.allowedSegments
          ? capturedData?.captured?.allowedSegments
          : allSegments,
        topBannerToBeShown: capturedData?.captured?.topBannerToBeShown,
        messageToBeDisplayed: capturedData?.captured?.messageToBeDisplayed,
        dailyTimeQuota: capturedData?.captured?.dailyTimeQuota,
        remainingTime: capturedData?.captured?.remainingTime,
        planType: capturedData?.captured?.planType,
      })
    );

    localStorage.setItem("token", capturedData?.user?.token);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        ...decoded,
        clientId: capturedData?.captured?.clientId,
        profileUpdateRequired: capturedData?.captured?.profileUpdateRequired,
        topBannerToBeShown: capturedData?.captured?.topBannerToBeShown,
        messageToBeDisplayed: capturedData?.captured?.messageToBeDisplayed,
        dailyTimeQuota: capturedData?.captured?.dailyTimeQuota,
        remainingTime: capturedData?.captured?.remainingTime,
        allowedSegments: capturedData?.captured?.allowedSegments
          ? capturedData?.captured?.allowedSegments
          : allSegments,
        planType: capturedData?.captured?.planType,
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    start();
    const { data, error, message } = await login(
      formData.memberCode,
      formData.loginID,
      formData.password
    );

    if (data) {
      if (data?.token) {
        var captured;
          captured = await captureLogin({
            loginId: formData.loginID,
            loginTime: new Date().toLocaleString(),
            memberId: formData.memberCode,
            status: "SUCCESS",
            reason: message,
            type: "LOGIN",
          });
        setCapturedData({
          user: data,
          captured: captured.data,
        });

        if (captured?.data?.planType === "TRIAL_FINAL") {
          setOpen(true);
          setModalMessage(expiryMessage);
        } else if (captured?.data?.planType === "TRIAL_WARNING") {
          setOpen(true);
          setModalMessage(warningMessage);
        } else if (
          captured?.data?.planType === "TRIAL_EXPIRED" &&
          captured?.data?.remainingTime > 0
        ) {
          setOpen(true);
          setModalMessage(
            `You have ${Math.floor(
              captured?.data?.remainingTime / 60
            )} minutes of free usage left for today. You can upgrade to a paid plan to enjoy unlimited usage.`
          );
        } else if (
          captured?.data?.planType === "TRIAL_EXPIRED" &&
          captured?.data?.remainingTime <= 0
        ) {
          setOpen(true);
          setModalMessage(
            `You have used your free ${Math.floor(
              captured?.data?.dailyTimeQuota / 60
            )} minutes of usage for today. Daily quota is reset at midnight. You can upgrade to a paid plan to enjoy unlimited usage.`
          );
        } else if (captured?.data?.planType === "TRIAL_SUNSET") {
          setOpen(true);
          setModalMessage(sunsetMessage);
        } else if (true) {
          setOpen(true);
          setModalMessage(x);
        } else {
          const decoded = decode(data?.token);
          toast("Login successful", {
            type: "success",
          });
          setIsLoading(false);
          dispatch(
            loginUser({
              ...decoded,
              clientId: captured?.data?.clientId,
              profileUpdateRequired: captured?.data?.profileUpdateRequired,
              token: data?.token,
              allowedSegments: capturedData?.captured?.data?.allowedSegments
                ? capturedData?.captured?.data?.allowedSegments
                : allSegments,
              topBannerToBeShown: captured?.data?.topBannerToBeShown,
              messageToBeDisplayed: captured?.data?.messageToBeDisplayed,
            })
          );

          localStorage.setItem("token", data?.token);
          localStorage.setItem(
            "userData",
            JSON.stringify({
              ...decoded,
              clientId: captured?.data?.clientId,
              profileUpdateRequired: captured?.data?.profileUpdateRequired,
              allowedSegments: capturedData?.captured?.data?.allowedSegments
                ? capturedData?.captured?.data?.allowedSegments
                : allSegments,
              topBannerToBeShown: captured?.data?.topBannerToBeShown,
              messageToBeDisplayed: captured?.data?.messageToBeDisplayed,
              planType: captured?.data?.planType,
            })
          );
        }
      }
    }
    if (error) {
      toast(message, {
        type: "error",
      });
      setIsLoading(false);
      stop();
      const captured = await captureLogin(
        formData.memberCode,
        formData.loginID,
        new Date().toLocaleString(),
        "FAILURE",
        message
      );
      console.log(captured);
    }
  };

  if (user) {
    return (
      <Navigate
        to={urlParams.get("redirect") ? `/?${urlParams.get("redirect")}` : "/"}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
           padding: 1,
        }}
      >
        <CssBaseline />
        <Grid
          item
          md={8}
          // xs={false}
          sm={false}
          sx={{
            backgroundImage: "url(/fundsmap_cover.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#000",
            backgroundSize: "contain",
            backgroundPosition: "center",
            overflow: "hidden",
             borderRadius: 3,
          }}
        />
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          component={Paper}
          elevation={0}
          square
        >
          <Box
            sx={{
              // my: 8,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "black",
                fontSize: "30px",
              }}
              sizes="xl"
            >
              <AccountCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            {showAlert && <Alert severity={alertVariant}>{alertMessage}</Alert>}
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  margin="normal"
                  size="small"
                  type="number"
                  required
                  fullWidth
                  id="memberCode"
                  label="Member Code"
                  name="memberCode"
                  autoFocus
                  value={formData.memberCode}
                  onChange={({ target }) =>
                    setFormData({
                      ...formData,
                      memberCode: target.value,
                    })
                  }
                  disabled={isLoading}
                />
                <Tooltip title="Your Member Code">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="loginID"
                  label="Login ID"
                  name="loginID"
                  value={formData.loginID}
                  onChange={({ target }) =>
                    setFormData({
                      ...formData,
                      loginID: target.value,
                    })
                  }
                  disabled={isLoading}
                />
                <Tooltip title="This will be the Login ID provided by NSE. This is the sub user ID generated on ENIT portal.">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FormControl fullWidth required margin="normal">
                  <InputLabel
                    size="small"
                    htmlFor="outlined-adornment-password"
                  >
                    Password
                  </InputLabel>

                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    size="small"
                    onChange={({ target }) =>
                      setFormData({
                        ...formData,
                        password: target.value,
                      })
                    }
                    disabled={isLoading}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Tooltip title="Check 'How to generate password?' link below if you don't have the password already.">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ margin: "20px 0" }}
                size="large"
                disabled={
                  formData.loginID.length === 0 ||
                  formData.memberCode.length === 0 ||
                  formData.password.length === 0 ||
                  isLoading
                }
              >
                {isLoading ? <CircularProgress /> : "Login"}
              </Button>
              <Box>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Link
                    href="https://www.fundsmap.com/x-connect"
                    target="_blank"
                    variant="body2"
                  >
                    New User? Click here.
                    <OpenInNewIcon
                      fontSize="8px"
                      sx={{
                        marginLeft: "5px",
                      }}
                    />
                  </Link>
                  <Link
                    href="/generate-password"
                    target="_blank"
                    variant="body2"
                  >
                    How to Generate Password?
                    <OpenInNewIcon
                      fontSize="8px"
                      sx={{
                        marginLeft: "5px",
                      }}
                    />
                  </Link>
                </Stack>
              </Box>
              <br></br>
              <Box>
                <Stack>
                  <Link
                    href="/generate-password"
                    target="_blank"
                    variant="body2"
                  >
                    Unable to login? Check FAQs
                    <OpenInNewIcon
                      fontSize="8px"
                      sx={{
                        marginLeft: "5px",
                      }}
                    />
                  </Link>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "10px",
            p: 4,
          }}
        >
          <p>{modalMessage}</p>
          <Stack
            direction="row"
            justifyContent="flex-end"
            width="100%"
            spacing={2}
          >
            {capturedData?.captured?.userLoginAllowed ? (
              <Button
                variant="contained"
                color="info"
                onClick={continueLogin}
                disabled={
                  capturedData?.captured?.planType === "TRIAL_EXPIRED" &&
                  capturedData?.captured?.remainingTime === 0
                }
              >
                Continue To Extranet Portal
              </Button>
            ) : (
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  window.open(
                    "https://form.jotform.com/231091411837047&memcode=" +
                      capturedData?.captured?.memberId,
                    "_blank"
                  );
                  captureActivity({
                    clientId: capturedData?.captured?.clientId,
                    memberId: capturedData?.captured?.memberId,
                    activityType: "X_REWIND_LOGIN",
                  });
                }}
              >
                Request Trial Extension
              </Button>
            )}
            {capturedData?.captured?.planType === "TRIAL_WARNING" ||
            capturedData?.captured?.planType === "TRIAL_FINAL" ||
            capturedData?.captured?.planType === "TRIAL_EXPIRED" ||
            capturedData?.captured?.planType === "TRIAL_SUNSET" ? (
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  window.open(
                    "https://form.jotform.com/230833067757058",
                    "_blank"
                  );
                  captureActivity({
                    clientId: capturedData?.captured?.clientId,
                    memberId: capturedData?.captured?.memberId,
                    activityType: "CHECK_PAID_PLANS_LOGIN",
                  });
                }}
              >
                Check paid plans
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  window.open("https://www.fundsmap.com/x-rewind", "_blank");
                  captureActivity({
                    clientId: capturedData?.captured?.clientId,
                    memberId: capturedData?.captured?.memberId,
                    activityType: "X_REWIND_LOGIN",
                  });
                }}
              >
                View Backup Solution
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Login;
