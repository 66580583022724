import Bottleneck from "bottleneck";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { statusCodeMessage } from "../constants";
import CryptoJS from "crypto-js";
import { bulkDownload } from "../api/download/bulkdownload";
import ToastError from "../components/ToastError";
import { logoutUser } from "../redux/slices/userSlice";
import { store } from "../redux/store";
import { captureLogin } from "../api/auth/capture";

export const statusCodeParser = (code) => {
  if (code) return statusCodeMessage[code];
  else return null;
};

export const encryptString = async (secret, password) => {
  console.log("Password: ", password);
  console.log("Secret: ", secret);
  var passwordWordArray = CryptoJS.enc.Utf8.parse(password);
  var secretBase64WordArray = CryptoJS.enc.Base64.parse(secret);
  const encryptedString4 = CryptoJS.AES.encrypt(
    passwordWordArray,
    secretBase64WordArray,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
      format: JsonFormatter,
    }
  );
  console.log(
    "Encrypted string: ",
    CryptoJS.enc.Base64.stringify(encryptedString4.ciphertext)
  );
  return CryptoJS.enc.Base64.stringify(encryptedString4.ciphertext);
};

var JsonFormatter = {
  stringify: function (cipherParams) {
    // create json object with ciphertext
    var jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    // optionally add iv or salt
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }
    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    }
    // stringify json object
    return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr);
    // extract ciphertext from json object, and create cipher params object
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
    });
    // optionally extract iv or salt
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    }
    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    }
    return cipherParams;
  },
};

export const responseToDownload = (response, filename = "File") => {
  saveAs(response, filename);
};

export const logout = ({ loginId, memberCode, token }) => {
  console.log("Called logout..");
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
  captureLogin({
    loginId: loginId,
    loginTime: new Date().toLocaleString(),
    memberId: memberCode,
    status: "SUCCESS",
    reason: "logout",
    type: "LOGOUT",
  });
  store.dispatch(logoutUser());
};

const limiter = new Bottleneck({
  maxConcurrent: 3,
  minTime: 800,
});

export const bulkDownloadFunc = async (
  userType,
  segment,
  folderPath,
  date,
  token
) => {
  try {
    const { data, headers } = await bulkDownload(
      userType,
      segment,
      folderPath,
      date,
      token
    );
    if (data) {
      console.log(data);
      // Extract filename from header
      console.log("Will get filename from header");
      console.log(headers.get("Content-Disposition"));
      const filename = headers
        .get("Content-Disposition")
        .split(";")
        .find((n) => n.includes("filename="))
        .replace("filename=", "")
        .replaceAll('"', "")
        .trim();
      console.log("filename:", filename);
      responseToDownload(data, filename);
    }
  } catch (error) {
    console.log("Throwing error again", error);
    throw error;
  }
};

export const downloadFilesFolder = async ({
  userType,
  segment,
  path,
  date,
  token,
}) => {
  toast.promise(
    limiter.schedule(() =>
      bulkDownloadFunc(userType, segment, path, date, token)
    ),
    {
      error: {
        render({ data }) {
          return (
            <ToastError
              message={`${data?.message}`}
              responseCode={data?.nseResponseCode}
              fileName={`${path} for ${date}`}
              logout={logout}
            />
          );
        },
      },
      pending: `Downloading from ${path} for ${date}`,
      success: `Downloaded from ${path} for ${date}`,
    }
  );
};

export function isEncoded(uri) {
  uri = uri || "";
  return uri !== decodeURIComponent(uri);
}

export function fullyDecodeURI(uri) {
  while (isEncoded(uri)) {
    uri = decodeURIComponent(uri);
  }
  return uri;
}
