import axios from "axios";

export const captureLogin = async ({
  memberId,
  loginId,
  loginTime,
  status,
  reason,
  type,
}) => {
  try {
    const { data } = await axios({
      url: `${process.env.REACT_APP_FUNDS_MAP_BACKEND_URL}/api/v1/xconnect/leads/login/capture`,
      method: "POST",
      data: {
        memberId,
        loginId,
        loginTime,
        status,
        reason,
        type,
      },
    });
    return {
      data,
    };
  } catch (error) {
    new Error(error);
    return {
      error,
    };
  }
};
