import { Button } from "@mui/material";
import { statusCodeParser } from "../utils";

const ToastError = ({ responseCode, message, logout, fileName = "" }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      {statusCodeParser(responseCode)?.message + " " + fileName || message}
      {responseCode === 803 && (
        <Button variant="contained" color="error" size="small" onClick={logout}>
          Login
        </Button>
      )}
    </div>
  );
};

export default ToastError;
