import { createContext, useContext, useRef } from "react";
import LoadingBar from "react-top-loading-bar";

const TopLoaderContext = createContext();

const TopLoaderProvider = ({ children }) => {
  const loaderRef = useRef();
  const start = () => loaderRef.current.continuousStart();
  const stop = () => loaderRef.current.complete();

  return (
    <TopLoaderContext.Provider
      value={{
        start,
        stop,
      }}
    >
      <LoadingBar
        height={5}
        ref={loaderRef}
        style={{
          zIndex: 500,
        }}
      />
      {children}
    </TopLoaderContext.Provider>
  );
};

const useTopLoader = () => {
  const authContext = useContext(TopLoaderContext);
  if (authContext) {
    return authContext;
  } else {
    throw new Error("Something is wrong with auth context");
  }
};

export { TopLoaderProvider, useTopLoader };
