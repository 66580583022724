import { ChonkyIconName, defineFileAction } from "chonky";

// segments
export const FOSegment = defineFileAction({
  id: "FO",
  button: {
    name: "FO",
    toolbar: true,
    group: "Segment",
  },
});
export const CMSegment = defineFileAction({
  id: "CM",
  button: {
    name: "CM",
    toolbar: true,
    group: "Segment",
  },
});
export const SLBSegment = defineFileAction({
  id: "SLB",
  button: {
    name: "SLB",
    toolbar: true,
    group: "Segment",
  },
});

export const CDSegment = defineFileAction({
  id: "CD",
  button: {
    name: "CD",
    toolbar: true,
    group: "Segment",
  },
});

export const COSegment = defineFileAction({
  id: "CO",
  button: {
    name: "CO",
    toolbar: true,
    group: "Segment",
  },
});

export const MFSSSegment = defineFileAction({
  id: "MFSS",
  button: {
    name: "MFSS",
    toolbar: true,
    group: "Segment",
  },
});

export const DEBTSegment = defineFileAction({
  id: "DEBT",
  button: {
    name: "DEBT",
    toolbar: true,
    group: "Segment",
  },
});

export const WDMSegment = defineFileAction({
  id: "WDM",
  button: {
    name: "WDM",
    toolbar: true,
    group: "Segment",
  },
});

// user Types
export const COMMONUserType = defineFileAction({
  id: "COMMON",
  button: {
    name: "COMMON",
    toolbar: true,
    group: "User Type",
  },
});
export const MEMBERUserType = defineFileAction({
  id: "MEMBER",
  button: {
    name: "MEMBER",
    toolbar: true,
    group: "User Type",
  },
});

export const downloadFileAction = defineFileAction({
  id: "download-file-action",
  requiresSelection: true,
  fileFilter: (file) => !file.isDir,
  button: {
    name: "Download Files",
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.download,
  },
});

// bulk download
export const todayBulkDownload = defineFileAction({
  id: "bulk-download-today",
  requiresSelection: false,
  button: {
    name: "Bulk Download Today's files",
    toolbar: true,
    group: "Actions",
  },
});

export const yesterdayBulkDownload = defineFileAction({
  id: "bulk-download-yesterday",
  requiresSelection: false,
  button: {
    name: "Bulk Download Yesterday's files",
    toolbar: true,
    group: "Actions",
  },
});

// download folder group
export const todaysFilesFolderDownload = defineFileAction({
  id: "todaysFilesFolderDownload",
  requiresSelection: true,
  fileFilter: (file) => file.isDir,
  button: {
    name: "Today's Files",
    toolbar: true,
    group: "Download Folder",
    contextMenu: true,
    icon: ChonkyIconName.download,
  },
});
export const yesterdayFilesFolderDownload = defineFileAction({
  id: "yesterdayFilesFolderDownload",
  requiresSelection: true,
  fileFilter: (file) => file.isDir,
  button: {
    name: "Yesterdays's Files",
    toolbar: true,
    group: "Download Folder",
    icon: ChonkyIconName.download,
  },
});
export const t2FilesFolderDownload = defineFileAction({
  id: "t2FilesFolderDownload",
  requiresSelection: true,
  fileFilter: (file) => file.isDir,
  button: {
    name: "T-2 Files",
    toolbar: true,
    group: "Download Folder",
    icon: ChonkyIconName.download,
  },
});
export const t3FilesFolderDownload = defineFileAction({
  id: "t3FilesFolderDownload",
  requiresSelection: true,
  fileFilter: (file) => file.isDir,
  button: {
    name: "T-3 Files",
    toolbar: true,
    group: "Download Folder",
    icon: ChonkyIconName.download,
  },
});
export const t4FilesFolderDownload = defineFileAction({
  id: "t4FilesFolderDownload",
  requiresSelection: true,
  fileFilter: (file) => file.isDir,
  button: {
    name: "T-4 Files",
    toolbar: true,
    group: "Download Folder",
    icon: ChonkyIconName.download,
  },
});
