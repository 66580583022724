import axios from "axios";

export const api = axios.create({
  // baseURL: "http://localhost:8080/www.devconnect2nse.com:443",
  baseURL: `${process.env.REACT_APP_URL_BASE_URL}`,
  //baseURL: "https://nse-cors-reverse-proxy.herokuapp.com/https://www.devconnect2nse.com",
});


export const folderApi = axios.create({
  baseURL: `${process.env.REACT_APP_URL_FOLDER_BASE_URL}`,
});
