import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTimer } from "react-timer-hook";
import { selectUser } from "../redux/slices/userSlice";
import { logout } from "../utils";
import { captureActivity } from "../api/capture/activity";


// icons
import InfoIcon from "@mui/icons-material/Info";

const Timer = ({ expiryTimestamp }) => {
  const time = new Date();
  // time.setSeconds(time.getSeconds() + 5);
  time.setSeconds(time.getSeconds() + Number(expiryTimestamp));

  const { hours, seconds, minutes } = useTimer({
    expiryTimestamp: time,
    autoStart: true,
    onExpire: () => {
      console.warn("onExpire called");
      setOpen(true);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    },
  });

  // states
  const [open, setOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const user = useSelector(selectUser);

  const handleClose = () => {
    setOpen(false);
    logout({
      loginId: user.loginId,
      memberCode: user?.memberCd,
      token: user?.token,
    });
  };

  useEffect(() => {
    // ! change this logic
    const handleBeforeUnload = () => {
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      const currentTime = minutes * 60 + seconds;
      userData.remainingTime = currentTime;
      localStorage.setItem("userData", JSON.stringify(userData));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [minutes, seconds]);

  return (
    <Box>
      <Typography
        color={minutes <= 2 && "yellow"}
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        Your Time Left: {hours && hours < 10 ? `0${hours}` : hours}h :{" "}
        {minutes < 10 ? `0${minutes}` : minutes}m :{" "}
        {seconds < 10 ? `0${seconds}` : seconds}s
        <IconButton onClick={() => setInfoModalOpen(true)} color="error">
          <InfoIcon />
        </IconButton>
      </Typography>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "10px",
              p: 4,
            }}
          >
            <p>
              You have used your free {user?.dailyTimeQuota / 60} minutes of
              usage for today. Daily quota is reset at midnight. You can upgrade
              to a paid plan to enjoy unlimited usage.
            </p>
            <Stack
              direction="row"
              justifyContent="flex-end"
              width="100%"
              spacing={2}
            >
              <Button 
              variant="contained" 
              color="info"
              onClick={() => {
                window.open(
                  "https://form.jotform.com/230833067757058",
                  "_blank"
                );
                captureActivity({
                  clientId: user?.memberCd,
                  memberId: user?.loginId,
                  activityType: "CHECK_PAID_PLANS_LOGIN",
                });
              }}>
                Upgrade Plan
              </Button>
              <Button variant="contained" color="error" onClick={handleClose}>
                Logout
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "10px",
              p: 4,
            }}
          >
            <p>
              You are currently on a free plan. Under free plan, users are only
              allowed to stay logged in for{" "}
              {Math.floor(user?.dailyTimeQuota / 60)} minutes a day. The timer
              indicates the time remaining for you today. The daily quota timer
              is reset at midnight. Please ensure to logout after using the
              website to stop the timer. You can upgrade to a paid plan for
              unlimited access.
            </p>
            <Stack
              direction="row"
              justifyContent="flex-end"
              width="100%"
              spacing={2}
            >
              {/* also add to link to upgrade the plan */}
              <Button 
              variant="contained" 
              color="info"
              onClick={() => {
                window.open(
                  "https://form.jotform.com/230833067757058",
                  "_blank"
                );
                captureActivity({
                  clientId: user?.memberCd,
                  memberId: user?.loginId,
                  activityType: "CHECK_PAID_PLANS_LOGIN",
                });
              }}>
                Upgrade Plan
              </Button>

              <Button
                variant="contained"
                color="error"
                onClick={() => setInfoModalOpen(false)}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Timer;
