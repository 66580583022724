import { api } from "..";
import { statusCodeParser } from "../../utils";
import { NSEServiceError } from "../../utils/errors";

export const downloadFilesCall = async (
  userType,
  segment,
  folderPath,
  filename,
  token
) => {
  try {
    const { data } = await api({
      url: `/extranet-api/${userType.toLocaleLowerCase()}/file/download/2.0`,
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      params: {
        segment,
        folderPath,
        filename,
      },
    });
    return {
      data,
    };
  } catch (error) {
    console.log("error", error);
    let message;
    let responseCode;
    if (error?.response?.status === 404) {
      // for not found
      responseCode = 714;
      message = statusCodeParser(responseCode).message;
    } else if (error?.response?.status === 401) {
      // for unauthorized
      responseCode = 803;
      message = statusCodeParser(responseCode).message;
    } else {
      // for others
      responseCode = error?.response?.data?.responseCode[0] || null;
      message = statusCodeParser(responseCode).message || "Something is wrong";
    }
    throw new NSEServiceError(message, responseCode, error?.response?.status);
  }
};
