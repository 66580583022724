import { useEffect, useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { loginUser, selectUser } from "./redux/slices/userSlice";

// react router dom
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

// MUI
import { Alert, Box, CircularProgress } from "@mui/material";

// components
import SideBar from "./components/Sidebar";

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import GeneratePassword from "./pages/GeneratePassword";
import { allSegments } from "./constants";
import { captureActivity } from "./api/capture/activity";

const App = () => {
  const [showAlert, setShowAlert] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [urlParams] = useSearchParams(window.location.search);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenFromLS = localStorage.getItem("token");
    const userDataFromLS = localStorage.getItem("userData");
    if (tokenFromLS && userDataFromLS) {
      const userData = JSON.parse(userDataFromLS);
      dispatch(
        loginUser({
          ...userData,
          allowedSegments: userData?.allowedSegments
            ? userData?.allowedSegments
            : allSegments,
          token: tokenFromLS,
        })
      );
    }
    setIsLoading(false);
  }, [dispatch]);
  return (
    <>
      {process.env.REACT_APP_TOP_SHOW_ALERT === "true" &&
        showAlert &&
        user &&
        user?.topBannerToBeShown &&
        (user?.planType === "TRIAL_SUNSET" ||
        user?.planType === "TRIAL_WARNING" ? (
          <Alert
            square
            variant="filled"
            severity="error"
            onClose={() => setShowAlert(false)}
          >
            You are on an extended trial plan. Please upgrade to a paid plan for
            un-interrupted access. <br />
            Check out the pricing details by{" "}
            <a
              href="https://form.jotform.com/230833067757058"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                captureActivity({
                  clientId: user?.clientId,
                  memberId: user?.memberCd,
                  activityType: "CHECK_PAID_PLANS_BANNER",
                });
              }}
            >
              clicking here
            </a>
            . Contact support@fundsmap.com
          </Alert>
        ) : (
          <Alert
            square
            variant="filled"
            severity="success"
            onClose={() => setShowAlert(false)}
          >
            Take one-click multi segment backup of NSE Extranet files using our
            new backup utility - FundsMap X-Rewind!<br></br>
            <a
              href="https://www.fundsmap.com/x-rewind"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                captureActivity({
                  clientId: user?.clientId,
                  memberId: user?.memberCd,
                  activityType: "X_REWIND_BANNER",
                });
              }}
            >
              Check out demo and features by clicking here
            </a>
          </Alert>
        ))}

      {isLoading ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {user && <SideBar />}
          <Routes>
            <Route
              path="/"
              element={
                user ? (
                  <Home />
                ) : (
                  <Navigate
                    to={`/login?redirect=${encodeURIComponent(
                      urlParams.toString()
                    )}`}
                  />
                )
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/generate-password"
              element={!user ? <GeneratePassword /> : <Navigate to="/" />}
            />
            <Route
              path="/profile"
              element={user ? <Profile /> : <Navigate to="/" />}
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
