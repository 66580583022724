import { api } from "..";
import { statusCodeParser } from "../../utils";
import { NSEServiceError } from "../../utils/errors";

export const bulkDownload = async (
  userType,
  segment,
  folderPath,
  date,
  token
) => {
  try {
    const { data, headers, status } = await api({
      url: `/extranet-api/${userType.toLocaleLowerCase()}/file/download/2.0`,
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      params: {
        segment,
        folderPath: folderPath?.replaceAll("%2F", "/"),
        date,
      },
    });
    console.log("data", data);
    return {
      data,
      headers,
      status,
    };
  } catch (error) {
    console.log("error", error);
    let message;
    let responseCode;
    if (error?.response?.status === 404) {
      // for not found
      responseCode = 714;
      message = statusCodeParser(responseCode).message;
    } else if (error?.response?.status === 401) {
      // for unauthorized
      responseCode = 803;
      message = statusCodeParser(responseCode).message;
    } else {
      // for others
      responseCode = error?.response?.data?.responseCode[0] || null;
      message = statusCodeParser(responseCode).message || "Something is wrong";
    }
    throw new NSEServiceError(message, responseCode, error?.response?.status);
  }
};
