import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import { toast } from "react-toastify";
import { Box, Button, Grid } from "@mui/material";
import {
  ChonkyActions,
  ChonkyIconName,
  FullFileBrowser,
  defineFileAction,
} from "chonky";
import axios from "axios";

import { allSegments, segments, userTypes } from "../constants";
import { useTopLoader } from "../context/TopLoaderContext";
import { getFolderData } from "../api/browser/getFolderData";
import {
  downloadFilesFolder,
  fullyDecodeURI,
  logout,
  responseToDownload,
  statusCodeParser,
} from "../utils";
import { downloadFilesCall } from "../api/browser/downloadFiles";
import {
  COMMONUserType,
  MEMBERUserType,
  // CMSegment,
  // FOSegment,
  // SLBSegment,
  // CDSegment,
  // COSegment,
  // MFSSSegment,
  // DEBTSegment,
  // WDMSegment,
  downloadFileAction,
  yesterdayBulkDownload,
  todayBulkDownload,
  todaysFilesFolderDownload,
  yesterdayFilesFolderDownload,
  t2FilesFolderDownload,
  t3FilesFolderDownload,
  t4FilesFolderDownload,
} from "../constants/fileActions";
import QuickDownload from "../components/QuickDownload";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const controllerRef = useRef(null);

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const navigate = useNavigate();
  const [segment, setSegment] = useState(() => {
    const paramSegment = urlParams.get("segment");
    return allSegments.includes(paramSegment) ? paramSegment : segments[1];
  });

  const [userType, setUserType] = useState(() => {
    const paramUserType = urlParams.get("userType");
    return userTypes.includes(paramUserType) ? paramUserType : userTypes[1];
  });
  const user = useSelector(selectUser);
  const initialFileChain = [
    {
      id: "user-type",
      name: userType,
      isDir: true,
      openable: false,
    },
    {
      id: "segment",
      name: segment,
      isDir: true,
      openable: false,
    },
    {
      id: crypto.randomUUID(),
      name: "/",
      isDir: true,
    },
  ];

  const [folderChain, setFolderChain] = useState(initialFileChain);
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const { start, stop } = useTopLoader();

  function sortOrder(a, b) {
    //sort files in desc order of modDate
    return b.modDate < a.modDate ? -1 : b.modDate > a.modDate ? 1 : 0;
  }

  const handleParamChange = (param, value) => {
    urlParams.set(param, value);
    navigate({
      pathname: "",
      search: urlParams.toString(),
    });
  };

  const getFolder = async (userType, segment, folderPath, signal, payload) => {
    start();
    setIsLoading(true);
    try {
      const { data } = await getFolderData(
        userType,
        segment,
        folderPath,
        user?.token,
        signal
      );
      if (data) {
        const filesData = data?.data
          ?.map((file, index) => ({
            // id: file?.name,
            id: index + 1,
            name: file?.name || "Untitled",
            isDir: file?.type === "Folder",
            folderPath: file?.folderPath,
            modDate: file?.lastUpdated,
            ext: (file?.name || "Untitled").includes(".") ? undefined : "",
          }))
          .sort(sortOrder);
        if (payload) {
          if (folderChain.map((i) => i.id).includes(payload?.targetFile.id)) {
            const sliceIndex =
              folderChain.map((i) => i.id).indexOf(payload?.targetFile.id) + 1;

            setFolderChain(folderChain.slice(0, sliceIndex));
          } else {
            setFolderChain([
              ...folderChain,
              {
                id: payload?.targetFile.id,
                isDir: payload?.targetFile?.isDir,
                name: payload?.targetFile?.name,
                folderPath: payload?.targetFile?.folderPath,
              },
            ]);
          }
        }

        stop();
        handleParamChange("path", folderPath);
        setFiles(filesData);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (axios.isCancel(error)) {
        console.log("Cancelled error.. do nothing..");
      } else if (error?.nseResponseCode === 803) {
        stop();
        toast(
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            {statusCodeParser(error?.nseResponseCode)?.message}
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={logout}
            >
              Login
            </Button>
          </div>,
          {
            type: "error",
            toastId: "get-folder-error",
            autoClose: false,
          }
        );
      } else if (statusCodeParser(error?.nseResponseCode)?.message) {
        stop();
        toast(statusCodeParser(error?.nseResponseCode)?.message, {
          type: "error",
        });
      } else {
        stop();
        toast("Failed to Load Data", {
          type: "error",
        });
      }
    }
  };

  const downloadFile = async (userType, segment, folderPath, filename) => {
    start();
    const { data, error, responseCode } = await downloadFilesCall(
      userType,
      segment,
      folderPath,
      filename,
      user?.token
    );
    if (data) {
      stop();
      responseToDownload(data, filename);
    } else if (error) {
      stop();
      if (responseCode === "803") {
        toast(
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            {statusCodeParser(responseCode)?.message}
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => {
                logout();
              }}
            >
              Login
            </Button>
          </div>,
          {
            type: "error",
            toastId: "get-folder-error",
            autoClose: false,
          }
        );
      } else {
        stop();
        toast("Something is wrong", {
          type: "error",
        });
      }
    }
  };

  const refreshAction = defineFileAction({
    id: "refresh-action",
    requiresSelection: isLoading,
    button: {
      name: "Refresh",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.loading,
    },
  });

  const fileActions = [
    todaysFilesFolderDownload,
    yesterdayFilesFolderDownload,
    t2FilesFolderDownload,
    t3FilesFolderDownload,
    t4FilesFolderDownload,
    downloadFileAction,
    ...user?.allowedSegments?.map((segment) =>
      defineFileAction({
        id: segment,
        button: {
          name: segment,
          toolbar: true,
          group: "Segment",
        },
      })
    ),
    // FOSegment,
    // CMSegment,
    // SLBSegment,
    // CDSegment,
    // COSegment,
    // MFSSSegment,
    // DEBTSegment,
    // WDMSegment,
    todayBulkDownload,
    yesterdayBulkDownload,
    COMMONUserType,
    MEMBERUserType,
    refreshAction,
  ];

  const handleFileAction = ({ action, payload, state }) => {
    if (action.id === "open_files") {
      if (payload?.targetFile?.isDir) {
        console.log({
          userType,
          segment,
          filePath: `${
            payload?.targetFile?.folderPath
              ? `${payload?.targetFile?.folderPath}`
              : ""
          }${payload?.targetFile?.name}`,
        });

        if (controllerRef.current) {
          // cancel previous request
          controllerRef.current.abort();
        }
        const newController = new AbortController();
        controllerRef.current = newController;
        const signal = controllerRef.current.signal;

        getFolder(
          userType,
          segment,
          `${
            payload?.targetFile?.folderPath
              ? `${payload?.targetFile?.folderPath}`
              : ""
          }${payload?.targetFile?.name}`,
          signal,
          payload
        );
      } else if (!payload?.targetFile?.isDir) {
        toast.promise(
          downloadFile(
            userType,
            segment,
            payload?.targetFile?.folderPath,
            payload?.targetFile?.name
          ),
          {
            error: "Failed to download " + payload?.targetFile?.name,
            pending: "Downloading file " + payload?.targetFile?.name,
            success: "Downloaded File " + payload?.targetFile?.name,
          }
        );
      }
    } else if (action.id === "download-file-action") {
      state?.selectedFiles?.map((file) => {
        toast.promise(
          downloadFile(userType, segment, file?.folderPath, file?.name),
          {
            error: "Failed to download: " + file?.name,
            pending: "Downloading file: " + file?.name,
            success: "Downloaded file: " + file?.name,
          }
        );
        return 0;
      });
      console.log(state);
    } else if (action.id === "FO") {
      setSegment("FO");
    } else if (action.id === "CM") {
      setSegment("CM");
    } else if (action.id === "SLB") {
      setSegment("SLB");
    } else if (action.id === "CD") {
      setSegment("CD");
    } else if (action.id === "CO") {
      setSegment("CO");
    } else if (action.id === "MFSS") {
      setSegment("MFSS");
    } else if (action.id === "DEBT") {
      setSegment("DEBT");
    } else if (action.id === "WDM") {
      setSegment("WDM");
    } else if (action.id === "COMMON") {
      setUserType("COMMON");
    } else if (action.id === "MEMBER") {
      setUserType("MEMBER");
    }
    // bulk download
    else if (action.id === "bulk-download-today") {
      const path = folderChain
        .slice(3, folderChain.length)
        .map((i) => i.name)
        .join("/");
      const date = new Date();

      downloadFilesFolder({
        userType,
        segment,
        date: date
          .toLocaleDateString("in-en", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replaceAll("/", "-"),
        path: `/${path}`,
        token: user?.token,
      });
    } else if (action.id === "bulk-download-yesterday") {
      const path = folderChain
        .slice(3, folderChain.length)
        .map((i) => i.name)
        .join("/");

      let today = new Date();
      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      downloadFilesFolder({
        date: yesterday
          .toLocaleDateString("in-en", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replaceAll("/", "-"),
        userType,
        path: `/${path}`,
        segment,
        token: user?.token,
      });
    }
    // download folder group
    else if (action.id === "todaysFilesFolderDownload") {
      state.selectedFiles?.forEach((file) => {
        const date = new Date();
        downloadFilesFolder({
          date: date
            .toLocaleDateString("in-en", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replaceAll("/", "-"),
          path: `${file?.folderPath}/${file?.name}`,
          segment,
          token: user?.token,
          userType,
        });
      });
    } else if (action.id === "yesterdayFilesFolderDownload") {
      state.selectedFiles?.forEach((file) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        downloadFilesFolder({
          date: yesterday
            .toLocaleDateString("in-en", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replaceAll("/", "-"),
          path: `${file?.folderPath}/${file?.name}`,
          segment,
          token: user?.token,
          userType,
        });
      });
    } else if (action.id === "t2FilesFolderDownload") {
      state.selectedFiles?.forEach((file) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 2);
        downloadFilesFolder({
          date: yesterday
            .toLocaleDateString("in-en", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replaceAll("/", "-"),
          path: `${file?.folderPath}/${file?.name}`,
          segment,
          token: user?.token,
          userType,
        });
      });
    } else if (action.id === "t3FilesFolderDownload") {
      state.selectedFiles?.forEach((file) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 3);
        downloadFilesFolder({
          date: yesterday
            .toLocaleDateString("in-en", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replaceAll("/", "-"),
          path: `${file?.folderPath}/${file?.name}`,
          segment,
          token: user?.token,
          userType,
        });
      });
    } else if (action.id === "t4FilesFolderDownload") {
      state.selectedFiles?.forEach((file) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 4);
        downloadFilesFolder({
          date: yesterday
            .toLocaleDateString("in-en", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replaceAll("/", "-"),
          path: `${file?.folderPath}/${file?.name}`,
          segment,
          token: user?.token,
          userType,
        });
      });
    } else if (action.id === "refresh-action") {
      setFiles([]);
      const path = fullyDecodeURI(urlParams.get("path")) || "/";

      if (controllerRef.current) {
        // cancel previous request
        controllerRef.current.abort();
      }
      const newController = new AbortController();
      controllerRef.current = newController;
      const signal = controllerRef.current.signal;
      getFolder(userType, segment, path, signal, null);
    }
  };

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    const path = fullyDecodeURI(urlParams.get("path")) || "/";
    console.log({ path });
    if (!path || path === "null") {
      handleParamChange("path", "/");
      setFolderChain(initialFileChain);

      if (userType.length && segment.length) {
        handleParamChange("userType", userType);
        handleParamChange("segment", segment);
        getFolder(userType, segment, "/", signal);
      }
    } else {
      const files = path.split("/");

      setFolderChain([
        ...initialFileChain,
        ...files.map((i, index) => ({
          id: crypto.randomUUID(),
          name: i.replace("%20", " "),
          isDir: true,
          folderPath: `${files?.slice(0, index)?.join("/")}/`,
        })),
      ]);

      if (userType.length && segment.length) {
        handleParamChange("userType", userType);
        handleParamChange("segment", segment);
        getFolder(userType, segment, path, signal);
      }
    }

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    if (!isFirstRender) {
      setFiles([]);
      setFolderChain(initialFileChain);

      if (userType.length && segment.length) {
        handleParamChange("userType", userType);
        handleParamChange("segment", segment);
        getFolder(userType, segment, "/", signal);
      }
    } else {
      setIsFirstRender(false);
    }

    return () => {
      controllerRef.current.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, segment]);

  return (
    <Box
      padding={3}
      style={{
        height: "calc(100vh - 100px)",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
        }}
      >
        <Grid item md={8} xs={12}>
          <FullFileBrowser
            files={files}
            folderChain={folderChain}
            fileActions={fileActions}
            defaultFileViewActionId={ChonkyActions.EnableListView.id}
            onFileAction={handleFileAction}
            defaultSortActionId={null}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <QuickDownload />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
