import { IconButton, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// icons
import DownloadIcon from "@mui/icons-material/Download";
import { downloadFilesCall } from "../api/browser/downloadFiles";
import { logoutUser, selectUser } from "../redux/slices/userSlice";
import { toast } from "react-toastify";
import ToastError from "./ToastError";
import { downloadFilesFolder, responseToDownload } from "../utils";

const QuickDownloadListItem = ({ title, description, data, date, segment }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(logoutUser());
  };

  const downloadFileFunc = async (
    userType,
    segment,
    folderPath,
    filename,
    token
  ) => {
    const { data, error } = await downloadFilesCall(
      userType,
      segment,
      folderPath,
      filename,
      token
    );
    console.log(data);
    if (data) responseToDownload(data, filename);
    else if (error) {
      console.log("Throwing error again", error);
      throw error;
    }
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <Stack>
        <Typography fontWeight="bold">{title}</Typography>
        {description && (
          <Typography color="gray" fontSize={12}>
            {description}
          </Typography>
        )}
      </Stack>
      <IconButton
        sx={{ flexShrink: 0 }}
        color="primary"
        onClick={() => {
          data?.downloadLinks?.map(async (download) => {
            if (download?.type === "file") {
              toast.promise(
                downloadFileFunc(
                  download?.userType,
                  segment,
                  download?.folderPath,
                  download?.filename,
                  user.token
                ),
                {
                  error: {
                    render({ data }) {
                      console.log(data);
                      return (
                        <ToastError
                          message={data?.message}
                          responseCode={data?.nseResponseCode}
                          logout={logout({
                            loginId: user.loginId,
                            memberCode: user?.memberCd,
                            token: user?.token,
                          })}
                          fileName={
                            "Unable to download file: " + download?.filename
                          }
                        />
                      );
                    },
                  },
                  pending: "Downloading file " + download?.filename,
                  success: "Downloaded file " + download?.filename,
                }
              );
            } else if (download?.type === "folder") {
              downloadFilesFolder({
                date,
                segment,
                path: download?.folderPath,
                userType: download?.userType,
                token: user?.token,
              });
            }
            return 0;
          });
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Stack>
  );
};

export default QuickDownloadListItem;
