import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { MuiTelInput } from "mui-tel-input";
import { useDispatch, useSelector } from "react-redux";

import { loginUser, selectUser } from "../redux/slices/userSlice";

// icons
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { getProfileData } from "../api/profile/getProfileData";
import { editProfile } from "../api/profile/editProfile";
import { useTopLoader } from "../context/TopLoaderContext";
import { toast } from "react-toastify";

const Profile = () => {
  const user = useSelector(selectUser);
  const [profileData, setProfileData] = useState({
    personalName: "",
    companyName: "",
    email: "",
    mobile: "+91",
    planType: "",
    allowedSegments: "",
    noOfUsers: "",
    expiryDate: "",
  });
  const dispatch = useDispatch();
  const { start, stop } = useTopLoader();
  const [isLoading, setIsLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    personalName: "",
    companyName: "",
    email: "",
    mobile: "+91",
    countryCode: "",
  });

  const submitForm = async (e) => {
    e.preventDefault();
    start();
    setFormLoading(true);
    try {
      const { data } = await editProfile({
        clientId: user?.clientId,
        countryCode: formData.countryCode,
        emailId: formData.email,
        memberId: user?.memberCd,
        mobileNumber: Number(formData.mobile?.slice(3).replaceAll(" ", "")),
        organizationName: formData.companyName,
        personnelName: formData.personalName,
      });
      const payload = {
        ...user,
        profileUpdateRequired: false,
      };
      dispatch(loginUser(payload));
      localStorage.setItem("userData", JSON.stringify(payload));
      setProfileData({
        companyName: data?.organisationName,
        email: data?.emailId,
        mobile: `${data?.countryCode}${data?.mobileNumber}`,
        personalName: data?.personnelName,
        planType: data?.planType,
      });
      setEdit(false);
      toast.success("Edit Successful");
      stop();
      setFormLoading(false);
    } catch (error) {
      toast.error("Edit Failed");
      stop();
      setFormLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const { data } = await getProfileData({
        clientId: user?.clientId,
        memberId: user?.memberCd,
      });
      setIsLoading(false);
      if (data?.personnelName === null && data?.organisationName === null)
        setEdit(true);
      else {
        setFormData({
          companyName: data?.organisationName,
          email: data?.emailId,
          mobile: `${data?.countryCode} ${data?.mobileNumber
            ?.toString()
            ?.slice(0, 5)} ${data?.mobileNumber?.toString()?.slice(5)}`,
          countryCode: data?.countryCode,
          personalName: data?.personnelName,
        });

        setProfileData({
          companyName: data?.organisationName,
          email: data?.emailId,
          mobile: `${data?.countryCode}${data?.mobileNumber}`,
          personalName: data?.personnelName,
          planType: data?.planType,
          allowedSegments:
            data?.allowedSegments?.length > 0
              ? data?.allowedSegments?.join(", ")
              : "ALL",
          expiryDate: data?.expiryDate ? data?.expiryDate?.join("-") : "NA",
          noOfUsers: data?.noOfUsers,
        });
      }
    };
    getData();
  }, [user?.clientId, user?.memberCd]);
  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container
          maxWidth="md"
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Card
            elevation={0}
            sx={{
              border: "1px solid #ccc",
              padding: "10px 20px",
            }}
          >
            <Typography variant="h6" padding={1.5}>
              General Information
            </Typography>
            <List>
              <ListItem>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography fontWeight="bold">Member ID:</Typography>
                  <Typography>{user?.memberCd}</Typography>
                </Stack>
              </ListItem>
              <ListItem>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography fontWeight="bold">Login ID:</Typography>
                  <Typography>{user?.loginId}</Typography>
                </Stack>
              </ListItem>
            </List>
          </Card>
          <Card
            elevation={0}
            sx={{
              border: "1px solid #ccc",
              padding: "10px 20px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="h6" padding={1.5}>
                Communication Information
              </Typography>
              <IconButton onClick={() => setEdit(!edit)}>
                {edit ? <CloseIcon /> : <EditIcon />}
              </IconButton>
            </Stack>
            {edit ? (
              <form onSubmit={submitForm}>
                <Stack
                  spacing={2}
                  sx={{
                    padding: "0px 15px",
                  }}
                >
                  <TextField
                    type="email"
                    required
                    label="Primary Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={formLoading}
                    value={formData.email}
                    onChange={({ target }) =>
                      setFormData({
                        ...formData,
                        email: target.value,
                      })
                    }
                  />

                  <MuiTelInput
                    required
                    label="Mobile Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={formLoading}
                    defaultCountry="IN"
                    value={formData.mobile}
                    onChange={(number, info) =>
                      setFormData({
                        ...formData,
                        mobile: number,
                        countryCode: `+${info.countryCallingCode}`,
                      })
                    }
                  />
                  <TextField
                    required
                    label="Personnel Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={formLoading}
                    value={formData.personalName}
                    onChange={({ target }) =>
                      setFormData({
                        ...formData,
                        personalName: target.value,
                      })
                    }
                  />
                  <TextField
                    required
                    label="Company Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={formLoading}
                    value={formData.companyName}
                    onChange={({ target }) =>
                      setFormData({
                        ...formData,
                        companyName: target.value,
                      })
                    }
                  />

                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={
                      formLoading ||
                      !formData.companyName ||
                      !formData.email ||
                      formData.mobile.length !== 15 ||
                      !formData.personalName
                    }
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </Stack>
              </form>
            ) : (
              <List>
                <ListItem>
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <Typography fontWeight="bold">Primary Email ID:</Typography>
                    <Typography>{profileData?.email}</Typography>
                  </Stack>
                </ListItem>
                <ListItem>
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <Typography fontWeight="bold">Mobile Number:</Typography>
                    <Typography>{profileData?.mobile}</Typography>
                  </Stack>
                </ListItem>
                <ListItem>
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <Typography fontWeight="bold">Company Name:</Typography>
                    <Typography>{profileData?.companyName}</Typography>
                  </Stack>
                </ListItem>
                <ListItem>
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <Typography fontWeight="bold">Personnel Name:</Typography>
                    <Typography>{profileData?.personalName}</Typography>
                  </Stack>
                </ListItem>
              </List>
            )}
          </Card>
          <Card
            elevation={0}
            sx={{
              border: "1px solid #ccc",
              padding: "10px 20px",
            }}
          >
            <Typography variant="h6" padding={1.5}>
              Plan Information
            </Typography>
            <List>
              <ListItem>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography fontWeight="bold">Current Plan:</Typography>
                  <Typography>{profileData?.planType}</Typography>
                </Stack>
              </ListItem>
              <ListItem>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography fontWeight="bold">Allowed Segments:</Typography>
                  <Typography>{profileData?.allowedSegments}</Typography>
                </Stack>
              </ListItem>
              <ListItem>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography fontWeight="bold">No of Users:</Typography>
                  <Typography>{profileData?.noOfUsers}</Typography>
                </Stack>
              </ListItem>
              <ListItem>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography fontWeight="bold">Expiry Date:</Typography>
                  <Typography>{profileData?.expiryDate}</Typography>
                </Stack>
              </ListItem>
            </List>
          </Card>
        </Container>
      )}
    </Box>
  );
};

export default Profile;
