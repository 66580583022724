import { api } from "..";
import { statusCodeParser } from "../../utils";

export const login = async (memberCode, loginId, password) => {
  try {
    const { data } = await api({
      method: "post",
      url: "/extranet-api/login/2.0",
      data: {
        memberCode,
        loginId,
        password,
      },
    });
    //if successful
    if (data?.responseCode?.[0] === "601") {
      return {
        data,
        message: statusCodeParser(data?.responseCode?.[0]).message,
      };
    } else {
      throw new Error(data?.responseCode?.[0]);
    }
  } catch (error) {
    new Error(error);
    var errorMessage = null;
    if (error?.response?.status === 504 || error?.response?.status === 502) {
      errorMessage =
        "NSE's Extranet Service is down right now. Please contact msm@nse.co.in for resolution.";
    }
    var responseCode =
      error?.response?.data?.responseCode?.[0] || error?.response?.responseCode;
    if (!responseCode) {
      //response code an also be in this format
      responseCode = error?.response?.data?.code?.[0];
      if (!responseCode) {
        console.log("problem", error?.message);
        responseCode = error?.message;
      }
    }
    return {
      error,
      responseCode: responseCode ? responseCode : null,
      message: errorMessage
        ? errorMessage
        : responseCode
        ? statusCodeParser(responseCode)?.message
        : "Unable to login",
    };
  }
};
