import { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// mui date pickers
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import { segments } from "../constants";
import CustomAccordion from "./CustomAccordion";
import QuickDownloadListItem from "./QuickDownloadListItem";
import {
  commonQuickDownloadData,
  memberQuickDownloadData,
} from "../constants/quickDownloadData";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";

const QuickDownload = () => {
  const user = useSelector(selectUser);
  const [date, setDate] = useState(dayjs());
  const [segment, setSegment] = useState(segments[0]);
  const [showAlert, setShowAlert] = useState(true);
  const handleChange = (e) => {
    setSegment(e.target.value);
  };

  return (
    <Box padding={3}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Quick Download
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: 180,
              }}
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              shouldDisableDate={(date) => {
                const today = dayjs();
                const diff = today.diff(date, "day");
                return diff < 0 || diff > 7;
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FormControl
            sx={{
              width: "100px",
            }}
          >
            <InputLabel id="demo-simple-select-label">Segment</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={segment}
              onChange={handleChange}
            >
              {user?.allowedSegments.map((segment) => (
                <MenuItem key={segment} value={segment}>
                  {segment}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Divider sx={{ margin: "20px 0" }} />
      <Stack spacing={2}>
        <CustomAccordion title="Common">
          <List>
            {commonQuickDownloadData
              .filter(function (quickDownload) {
                return quickDownload.segment === segment;
              })
              .map((data, index) => (
                <ListItem key={index}>
                  <QuickDownloadListItem
                    title={data.title}
                    description={data.description}
                    data={data}
                    date={date?.format("DD-MM-YYYY")}
                    segment={segment}
                  />
                </ListItem>
              ))}
          </List>
        </CustomAccordion>
        <CustomAccordion title="Member">
          <List>
            {memberQuickDownloadData
              .filter(function (quickDownload) {
                return quickDownload.segment === segment;
              })
              .map((data, index) => (
                <ListItem key={index}>
                  <QuickDownloadListItem
                    title={data.title}
                    description={data.description}
                    data={data}
                    date={date?.format("DD-MM-YYYY")}
                    segment={segment}
                  />
                </ListItem>
              ))}
          </List>
        </CustomAccordion>
        {showAlert && (
          <Alert severity="info" onClose={() => setShowAlert(false)}>
            <AlertTitle>Skip Browsing, Download Directly! </AlertTitle>
            We would like to know which files/folders you commonly download.
            This will help us include them the "Quick Downloads" section.{" "}
            <b>
              Please write to us at{" "}
              <Link href="mailto:support@fundsmap.com" target="_blank">
              support@fundsmap.com
              </Link>
              , mentioning the segment, folders/files, member/common.
            </b>
          </Alert>
        )}
      </Stack>
    </Box>
  );
};

export default QuickDownload;
