import axios from "axios";

export const captureActivity = async ({ clientId, memberId, activityType }) => {
  try {
    const date = new Date();
    const { data } = await axios({
      url: `${process.env.REACT_APP_FUNDS_MAP_BACKEND_URL}/api/v1/xconnect/leads/activity/capture`,
      method: "POST",
      data: {
        clientId,
        memberId,
        clientTime: date.toLocaleDateString("in-en", {
          hour12: true,
          minute: "2-digit",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
        }),
        sourceSystem: "XCONNECT",
        activityType,
      },
    });
    return {
      data,
    };
  } catch (error) {
    new Error(error);
    return {
      error,
    };
  }
};
