import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomAccordion = ({ title, description, children }) => {
  return (
    <Accordion variant="outlined" defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography
          sx={{
            fontSize: 20,
            flexShrink: 0,
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        {description && (
          <Typography sx={{ color: "text.secondary" }}>
            {description}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          maxHeight: 400,
          overflowY: "scroll",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
