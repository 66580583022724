import axios from "axios";

export const getProfileData = async ({ memberId, clientId }) => {
  try {
    const { data } = await axios({
      url: `${process.env.REACT_APP_FUNDS_MAP_BACKEND_URL}/api/v1/xconnect/clients/profile`,
      params: {
        clientId,
        memberId,
      },
    });

    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};
