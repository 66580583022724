import axios from "axios";

export const editProfile = async ({
  clientId,
  memberId,
  organizationName,
  personnelName,
  emailId,
  mobileNumber,
  countryCode,
}) => {
  try {
    const { data } = await axios({
      method: "put",
      url: `${process.env.REACT_APP_FUNDS_MAP_BACKEND_URL}/api/v1/xconnect/clients/profile`,
      data: {
        clientId,
        memberId,
        organisationName: organizationName,
        personnelName: personnelName,
        emailId: emailId,
        mobileNumber: mobileNumber,
        countryCode: countryCode,
      },
    });

    return { data };
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
