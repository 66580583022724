export const segments = ["CM", "FO", "CD"];
export const allSegments = [
  "CM",
  "FO",
  "CD",
  "SLB",
  "WDM",
  "MFSS",
  "CO",
  "DEBT",
];
export const userTypes = ["COMMON", "MEMBER"];
export const statusCodeMessage = {
  601: {
    message: "Success",
    type: "success",
  },
  605: {
    message:
      "NSE Error 605: NSE's service is facing some internal issues. Please contact msm@nse.co.in for resolution.",
    type: "failed",
  },
  606: {
    message: "NSE Error 606: Method unknown",
    type: "failed",
  },
  607: {
    message: "NSE Error 607: Concurrent User limit exceeded",
    type: "failed",
  },
  701: {
    message: "NSE Error 701: Invalid member code or login id or password",
    type: "failed",
  },
  702: {
    message: "NSE Error 702: User is disabled",
    type: "failed",
  },
  703: {
    message: "NSE Error 703: Invalid member code or login id",
    type: "failed",
  },
  704: {
    message: "NSE Error 704: Member not eligible to access this segment",
    type: "failed",
  },
  706: {
    message: "NSE Error 706: Invalid request",
    type: "failed",
  },
  709: {
    message: "NSE Error 709: Invalid File",
    type: "failed",
  },
  710: {
    message: "NSE Error 710: Date invalid",
    type: "failed",
  },
  711: {
    message: "NSE Error 711: Segment invalid",
    type: "failed",
  },
  712: {
    message: "NSE Error 712: Folder path invalid",
    type: "failed",
  },
  713: {
    message: "NSE Error 713: Filename invalid",
    type: "failed",
  },
  714: {
    message: "NSE Error 714: File not Found",
    type: "failed",
  },
  715: {
    message: "NSE Error 715: Folder not Found",
    type: "failed",
  },
  720: {
    message:
      "NSE Error 720: User does not have access to the segment. Please check and provide user rights on ENIT portal and send mail to msm@nse.co.in for resolution.",
    type: "failed",
  },
  721: {
    message:
      "NSE Error 721: User does not have access to the service. Please send mail to msm@nse.co.in for resolution.",
    type: "failed",
  },
  802: {
    message: "NSE Error 802: Invalid token",
    type: "failed",
  },
  803: {
    message: "NSE Error 803: Authentication token expired",
    type: "failed",
  },
  917: {
    message: "NSE Error 917: Not valid record",
    type: "failed",
  },
  920: {
    message: "NSE Error 920: Invalid version no",
    type: "failed",
  },
  921: {
    message: "NSE Error 921: Invalid syntax",
    type: "failed",
  },
  923: {
    message: "NSE Error 923: Invalid URL",
    type: "failed",
  },
  924: {
    message: "NSE Error 924: Invalid content type",
    type: "failed",
  },
  925: {
    message: "NSE Error 925: Authentication server down",
    type: "failed",
  },
  956: {
    message: "NSE Error 956: Password attempt Limit Exceeded",
    type: "failed",
  },
  959: {
    message: "NSE Error 959: API call not allowed",
    type: "failed",
  },
};
