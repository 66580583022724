import axios from "axios";
import { folderApi } from "..";
import { statusCodeParser } from "../../utils";
import { NSEServiceError } from "../../utils/errors";

export const getFolderData = async (
  userType,
  segment,
  folderPath,
  token,
  signal
) => {
  try {
    const { data } = await folderApi({
      url: `/extranet-api/${userType.toLocaleLowerCase()}/content/2.0`,
      signal,
      params: {
        segment,
        folderPath,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data,
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      throw error;
    }
    console.log("error", error);
    let message;
    let responseCode;
    if (error?.response?.status === 404) {
      // for not found
      responseCode = 714;
      message = statusCodeParser(responseCode).message;
    } else if (error?.response?.status === 401) {
      // for unauthorized
      responseCode = 803;
      message = statusCodeParser(responseCode).message;
    } else {
      // for others
      responseCode = error?.response?.data?.responseCode[0] || null;
      message = statusCodeParser(responseCode).message || "Something is wrong";
    }
    throw new NSEServiceError(message, responseCode, error?.response?.status);
  }
};
